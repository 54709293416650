import React, { useContext, useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType, Prices, Discount } from '../../../contexts/data';

import { ModalContext } from '../../../contexts/modal';
import type { ModalContextValueType } from '../../../contexts/modal';

import { StepsContext } from '../../../contexts/steps';
import type { StepsContextValueType } from '../../../contexts/steps';

import FormField from '../../../components/FormField';
import ReviewTable from '../../../components/ReviewTable';
import ErrorAlert from '../../../components/Alerts/Error';
import Alert from '../../../components/Alert';

import ReviewDescription from './ReviewDescription';
import ReviewPrices from './ReviewPrices';
import ReviewButtons from './ReviewButtons';

import API from '../../../api/api';

type FormValues = {
  discount: string;
};

type ReviewProps = {
  onNext: () => void;
};

const Review = ({ onNext }: ReviewProps) => {
  const {
    selectedPackage,
    bookingItems: [bookingItems],
    bookingData: [bookingData, setBookingData],
    externalId,
    partyNameString
  } = useContext(DataContext) as DataContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const {
    activeStep,
    goToPrevStep,
    stepWillChange: [stepWillChange],
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const { handleSubmit, control, watch, setValue } = useForm<FormValues>();

  const [promoDisabled, setPromoDisabled] = useState(true);
  const [hidePromoCode, setHidePromoCode] = useState(false);
  const [prices, setPrices] = useState<Prices>({});
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [discountError, setDiscountError] = useState('');
  const [weekday, setWeekday] = useState(-1);

  watch(data => {
    setDiscountError('');
    const emptyFields = Object.entries(data).filter(item => {
      return !item[1];
    });

    if (emptyFields.length) {
      setPromoDisabled(true);
      return;
    }

    setPromoDisabled(false);
  });

  const getPackageItem = () => {
    return {
      productId: selectedPackage?.products[0].id || '',
      quantity: bookingData.extra.jumpersNumber,
      bookingDate: bookingData.extra.bookingDate,
      startTime: bookingData.extra.startTime,
      name: selectedPackage?.products[0].name || '',
      parentName: selectedPackage?.name || '',
      cost: selectedPackage?.products[0].cost || 0,
      tax: selectedPackage?.products[0].tax || 0,
      parentId: selectedPackage?.id || '',
      partyPackageInclusions: bookingItems.filter(bookingItem => bookingItem.isIncluded)
    };
  };

  const validateDiscountCode = async (code: string) => {
    try {
      const packageItem = getPackageItem();

      const filteredBookingItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);

      const discountRes = await API.validateDiscountCode({
        discounts: [{ code: code }],
        items: [...filteredBookingItems, packageItem]
      });

      if (discountRes.errors) {
        hideLoading();
        openModal(
          <ErrorAlert
            title="Error"
            description={discountRes.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking', 'reload']}
          />,
          false
        );
        return;
      }

      const { validationMessage } = discountRes.discounts[0];

      if (validationMessage) {
        setDiscountError(validationMessage);
        return false;
      }

      setDiscounts(discountRes.discounts);
      return discountRes;
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  const onSubmitPromoCode: SubmitHandler<FormValues> = async data => {
    showLoading();
    const discountRes = await validateDiscountCode(data.discount);
    if (discountRes) await updatePrices(discountRes.discounts[0].code);
    hideLoading();
  };

  const getBookingPayload = (discountCode?: string) => {
    const customer = {
      firstName: 'John',
      lastName: 'Doe',
      email: bookingData.customer.email,
      phone: '(000)-000-0000'
    };

    const packageItem = getPackageItem();

    const filteredBookingItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);

    const items = [...filteredBookingItems, packageItem];

    const payload = {
      name: partyNameString,
      externalId,
      customer,
      discounts: discountCode ? [{ code: discountCode }] : [],
      capacityReservationId: bookingData.extra.capacityReservationId || undefined,
      items
    };

    return payload;
  };

  const updatePrices = async (discountCode?: string) => {
    try {
      const payload = getBookingPayload(discountCode);
      const costsResponse = await API.fetchBookingCosts(payload);

      if (costsResponse.errors) {
        hideLoading();
        openModal(
          <ErrorAlert
            title="Error"
            description={costsResponse.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking']}
          />,
          false
        );
        return;
      }
      setPrices(costsResponse.bookingCosts);
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  const automaticDiscountCode = async () => {
    setHidePromoCode(false);
    const automaticDiscoutCode = '10BDAYWEEK';
    setValue('discount', automaticDiscoutCode);
    const discountRes = await validateDiscountCode(automaticDiscoutCode);
    if (discountRes) await updatePrices(discountRes.discounts[0].code);
  };

  const checkPrices = async () => {
    showLoading();

    // Update weekdays array to include Sunday (0)
    const weekdays = [0, 1, 2, 3, 4];

    const splitedDate = bookingData.extra.bookingDate.split('-');

    const month = parseInt(splitedDate[1], 10) - 1; // Months are 0-based (0 = January)
    const day = parseInt(splitedDate[2], 10);
    const year = parseInt(splitedDate[0], 10);

    const weekday = new Date(year, month, day).getDay();

    if (weekdays.includes(weekday)) {
      setWeekday(weekday);
      // For Sunday, we don't want to apply the automatic discount
      if (weekday === 0) {
        setHidePromoCode(false);
        setValue('discount', '');
        setDiscounts([]);
        await updatePrices();
      } else {
        // For other weekdays, we want to apply the automatic discount
        await automaticDiscountCode();
      }
    } else {
      // For Saturday, we want to hide the promo code input
      setValue('discount', '');
      setDiscounts([]);
      setHidePromoCode(true);
      await updatePrices();
    }

    hideLoading();
  };

  const handleResetStep = () => {
    setDiscounts([]);
    setValue('discount', '');
  };

  const handleSave = (callback: () => void) => {
    setBookingData({
      ...bookingData,
      extra: {
        ...bookingData.extra,
        prices,
        discounts
      }
    });

    callback();
  };

  const handlePrev = () => {
    if (!bookingData.extra.prices) {
      handleResetStep();
    }

    goToPrevStep();
  };

  const handleNext = async () => {
    handleSave(onNext);
  };

  const handleStepChange = () => {
    if (!bookingData.extra.prices) {
      handleResetStep();
    }

    stepWillChange.stepValue && goToStep(stepWillChange.stepValue);
  };

  useEffect(() => {
    if (activeStep && activeStep.value === 'review-order' && selectedPackage) {
      checkPrices();
    }
  }, [activeStep, selectedPackage]);

  useEffect(() => {
    if (!prices.amountOwing) {
      return;
    }

    setBookingData({
      ...bookingData,
      extra: {
        ...bookingData.extra,
        totalCost: (prices.total || 0) - (prices.tax || 0) + (prices.discount || 0)
      }
    });
  }, [prices]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'review-order' && stepWillChange.willChange) {
      handleStepChange();
    }
  }, [stepWillChange.willChange]);

  return (
    <>
      <ReviewDescription />
      <div className="review-step">
        <div className="container-sm">
          <ReviewTable />

          <Alert
            className="steps-warning-message"
            title={
              <>
                A <span>${prices.amountOwing?.toFixed(2)}</span> deposit is required to complete
                your booking.
              </>
            }
            type="default-font-black"
          >
            If you’re booking your party within 72 hours of the event, your deposit is
            non-refundable. Otherwise, all parties are fully refundable within the first 72 hours of
            booking. Your deposit will be applied toward the total cost of your party on the day of
            the event.
          </Alert>

          <Alert
            className="steps-warning-message"
            type="default-font-black"
            title="Sky Zone Elite Members save 20% on parties. Discount taken on party day."
          />

          <div className="review-step__order-summary">
            {!hidePromoCode && (
              <form
                className="review-step__promo-code"
                onSubmit={handleSubmit(onSubmitPromoCode)}
                noValidate
              >
                <Controller
                  name="discount"
                  control={control}
                  render={({ field }) => (
                    <FormField
                      type="text"
                      label={weekday === 0 ? 'Promo Code' : 'Weekday Promo Code'}
                      error={discountError}
                      {...field}
                      disabled={!!discounts.length}
                    />
                  )}
                />
                <button
                  type="submit"
                  className="apply-code"
                  disabled={promoDisabled || !!discounts.length}
                >
                  Apply
                </button>
              </form>
            )}

            <ReviewPrices prices={prices} />
          </div>
        </div>
      </div>

      <ReviewButtons handlePrev={handlePrev} handleNext={handleNext} />
    </>
  );
};

export default Review;
