import React, { useState, useContext, useEffect, useRef } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';
import { StepsContext } from '../../../contexts/steps';
import type { StepsContextValueType } from '../../../contexts/steps';
import Alert from '../../../components/Alert';
import GuestDetailsDescription from './GuestDetailsDescription';
import GuestDetailsForm from './GuestDetailsForm';
import GuestDetailsQuantity from './GuestDetailsQuantity';
import GuestDetailsButtons from './GuestDetailsButtons';

import type { FormValues } from './GuestDetailsForm';

type GuestDetailsProps = {
  onNext: (arg0?: string) => void;
};

const GuestDetails = ({ onNext }: GuestDetailsProps) => {
  const {
    bookingItems: [bookingItems, setBookingItems],
    bookingData: [bookingData, setBookingData],
    editPackage: [editPackage],
    isEditMode,
    isDecreasingJumpers: [isDecreasingJumpers, setIsDecreasingJumpers]
  } = useContext(DataContext) as DataContextValueType;

  const {
    activeStep,
    steps: [stepsData, setStepsData],
    stepWillChange: [stepWillChange, setStepWillChange],
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const [jumpersNumber, setJumpersNumber] = useState(editPackage?.quantity || 10);
  const [nonJumpersNumber, setNonJumpersNumber] = useState(bookingData.extra.nonJumpersNumber || 0);
  const [guestOfHonorCount, setGuestOfHonorCount] = useState(
    bookingData.extra.guestOfHonorCount || 1
  );
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isOverVenueLimit, setIsOverVenueLimit] = useState(false);
  const [hasAddonsSelected, setHasAddonsSelected] = useState(
    bookingItems.length > 0 ? true : false
  );

  const formRef = useRef<HTMLFormElement>(null);

  const checkVenueLimit = (jumpersNumber: number, nonJumpersNumber: number) => {
    if (
      bookingData.extra.partyCapacity &&
      jumpersNumber + nonJumpersNumber > bookingData.extra.partyCapacity
    ) {
      setIsOverVenueLimit(true);
      return;
    }

    setIsOverVenueLimit(false);
  };

  const checkDecreasingJumpers = (jumpersNumber: number) => {
    if (
      stepsData[2].done &&
      Math.floor(jumpersNumber / 5) !== Math.floor(bookingData.extra.jumpersNumber / 5) &&
      ((jumpersNumber < bookingData.extra.jumpersNumber && hasAddonsSelected) ||
        jumpersNumber >= bookingData.extra.jumpersNumber)
    ) {
      setIsDecreasingJumpers(true);
      return;
    } else {
      setIsDecreasingJumpers(false);
    }
  };

  const handleJumpersChange = (newValue: number) => {
    checkDecreasingJumpers(newValue);
    setJumpersNumber(newValue);
    checkVenueLimit(newValue, nonJumpersNumber);
  };

  const handleNonJumpersChange = (newValue: number) => {
    setNonJumpersNumber(newValue);
    checkVenueLimit(jumpersNumber, newValue);
  };

  const addGuestOfHonor = () => {
    if (guestOfHonorCount === 3) return;
    setGuestOfHonorCount(guestOfHonorCount + 1);
  };

  const removeGuestOfHonor = () => {
    setGuestOfHonorCount(guestOfHonorCount - 1);
  };

  useEffect(() => {
    checkBookingItems();
  }, [guestOfHonorCount]);

  const checkBookingItems = () => {
    const addonsItShouldHave = Math.floor(jumpersNumber / 5);
    const reviewedBookingItems = [...bookingItems].filter(item => {
      if (item.index === undefined || item.index < addonsItShouldHave) {
        return item;
      }
      return null;
    });
    setBookingItems(reviewedBookingItems);
  };

  const onSubmit: SubmitHandler<FormValues> = data => {
    if (hasAddonsSelected && isDecreasingJumpers) {
      checkBookingItems();
    }

    setBookingData({
      ...bookingData,
      customer: {
        ...bookingData.customer,
        email: isEditMode ? bookingData.customer.email : data.email
      },
      extra: {
        ...bookingData.extra,
        guestOfHonorCount,
        jumpersNumber,
        nonJumpersNumber,
        guestOfHonorFirstName: data.guestOfHonorFirstName,
        guestOfHonorDob: data.guestOfHonorDob,
        secondGuestOfHonorFirstName:
          guestOfHonorCount >= 2 ? data.secondGuestOfHonorFirstName : undefined,
        secondGuestOfHonorDob: guestOfHonorCount >= 2 ? data.secondGuestOfHonorDob : undefined,
        thirdGuestOfHonorFirstName:
          guestOfHonorCount >= 3 ? data.thirdGuestOfHonorFirstName : undefined,
        thirdGuestOfHonorDob: guestOfHonorCount >= 3 ? data.thirdGuestOfHonorDob : undefined
      }
    });

    if (stepWillChange.willChange && stepWillChange.stepValue) {
      goToStep(stepWillChange.stepValue);
      return;
    }

    if (isDecreasingJumpers) {
      if (!isEditMode) {
        stepsData[3].done = false;
        stepsData[4].done = false;
        setStepsData([...stepsData]);
      }
      onNext('select-addons');
    } else onNext();
  };

  const handlePrev = () => {
    window.location.href = document.referrer || 'https://www.skyzone.com/parties/';
  };

  const handleStepChange = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    setStepWillChange({ willChange: false });
  };

  useEffect(() => {
    if (bookingItems.length > 0) {
      setHasAddonsSelected(true);
    }
  }, [bookingItems]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'enter-guest-details' && isEditMode) {
      setNextDisabled(false);
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'enter-guest-details' && stepWillChange.willChange) {
      handleStepChange();
    }
  }, [stepWillChange.willChange]);

  return (
    <>
      <GuestDetailsDescription />
      <div className="guest-details container-sm" id="guest-details">
        <img
          alt=""
          className="guest-details__confetti"
          src="/images/confetti.png"
          width={161}
          height={180}
        />

        <GuestDetailsForm
          formRef={formRef}
          setNextDisabled={setNextDisabled}
          onSubmit={onSubmit}
          guestOfHonorCount={guestOfHonorCount}
          addGuestOfHonor={addGuestOfHonor}
          removeGuestOfHonor={removeGuestOfHonor}
        />

        <GuestDetailsQuantity
          jumpersNumber={jumpersNumber}
          nonJumpersNumber={nonJumpersNumber}
          handleJumpersChange={handleJumpersChange}
          handleNonJumpersChange={handleNonJumpersChange}
          isOverVenueLimit={isOverVenueLimit}
        />

        {isOverVenueLimit && (
          <Alert type="default">
            We like how you party! But your guest list has exceeded our party space capacity. Either
            adjust your counts down or give us a call at {bookingData.extra.parkPhoneNumber} to make
            sure we reserve a large enough space for your group!
          </Alert>
        )}
        {isDecreasingJumpers && (
          <Alert type="error">
            New jumper count? No problem! Please review your add-ons and package details next to
            make sure everything looks party perfect.
          </Alert>
        )}
      </div>
      <GuestDetailsButtons
        handlePrev={handlePrev}
        nextDisabled={nextDisabled}
        isOverVenueLimit={isOverVenueLimit}
        isDecresingJumpers={isDecreasingJumpers}
      />
    </>
  );
};

export default GuestDetails;
