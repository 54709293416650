/* eslint-disable @typescript-eslint/no-explicit-any */

import type {
  DraftBookingPayload,
  UpdateBookingPayload,
  DiscountCodePayload,
  CapacityReservationPayload
} from './api.d';
import Cookies from 'js-cookie';
export * from './api.d';

class API {
  IS_FROM_REVIEW_PAGE = false;
  MAX_RETRIES = 3;
  AZURE_API_URL: string;
  VENUE_NAME: string;
  PARTY_NAME: string;
  PHONE_NUMBER = '';
  PARK_NAME_URL = '';
  PARK_BRAND = '';
  PARK_NUMBER = '';
  ROLLER_NAME = '';
  DB_INTERNAL_ID: number;
  BOOKING_REF: string | null;
  healthCheckInterval: NodeJS.Timeout | null = null;
  LAST_CAPTCHA_VERIFICATION: Date | null = null;
  NEEDS_PRODUCT_STORAGE_REFRESH = false;

  constructor(isReviewPage: boolean) {
    const urlParams = new URLSearchParams(window.location.search);
    const searchPartyName = urlParams.get('party-name');
    const searchVenueName = urlParams.get('venue-name');
    let searchBookingRef = urlParams.get('booking-ref');
    const venueName = searchVenueName ? searchVenueName.toLowerCase().replaceAll(' ', '-') : '';
    const partyName = searchPartyName ? searchPartyName.toLowerCase().trim() : '';

    //remove any ? params from booking ref
    if (searchBookingRef) {
      searchBookingRef = searchBookingRef.split('?')[0];
    }

    if (!import.meta.env.VITE_FUNCTION_APP_URL && window.location.hostname !== 'localhost') {
      console.error('VITE_FUNCTION_APP_URL not found');
    }
    this.IS_FROM_REVIEW_PAGE = isReviewPage;
    this.VENUE_NAME = venueName;
    this.PARTY_NAME = partyName;
    this.DB_INTERNAL_ID = -1;
    this.AZURE_API_URL =
      window.location.hostname === 'localhost'
        ? 'http://localhost:7071/api'
        : `${import.meta.env.VITE_FUNCTION_APP_URL}/api`;

    this.BOOKING_REF = searchBookingRef;
  }

  loadCaptcha = () => {
    return new Promise((resolve, reject) => {
      window.captchaOnloadCallback = () => {
        resolve(grecaptcha);
      };
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        `https://www.google.com/recaptcha/api.js?render=${
          import.meta.env.VITE_PUBLIC_RECAPTCHA_KEY
        }&onload=captchaOnloadCallback`
      );
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.onerror = () => {
        reject(new Error('Failed to load reCAPTCHA script.'));
      };
      document.head.appendChild(script);
    });
  };

  async verifyCaptcha(keyword: string, retries: number) {
    const token = await window.grecaptcha.execute(import.meta.env.VITE_PUBLIC_RECAPTCHA_KEY, {
      action: keyword
    });
    const validationRes = await this.validateCaptcha(token, retries);
    this.LAST_CAPTCHA_VERIFICATION = new Date();
    return validationRes.data;
  }

  async syncExtraInfo() {
    const storedExtraInfo = localStorage.getItem(`extraParkInfo-${this.PARTY_NAME}`);

    if (storedExtraInfo) {
      const extraInfo = JSON.parse(storedExtraInfo);
      const extraInfoExpiryDate = new Date(extraInfo.expiry);

      if (
        extraInfoExpiryDate > new Date() &&
        extraInfo.partyName === this.PARTY_NAME &&
        extraInfo.rollerName === this.VENUE_NAME.replaceAll('-', '') &&
        extraInfo.guestServicesTextNumber !== undefined
      ) {
        this.DB_INTERNAL_ID = extraInfo.id;
        this.PHONE_NUMBER = extraInfo.phoneNumber;
        this.PARK_BRAND = extraInfo.parkBrand;
        this.PARK_NUMBER = extraInfo.parkNumber;
        this.ROLLER_NAME = this.VENUE_NAME.split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        this.NEEDS_PRODUCT_STORAGE_REFRESH = false;
        return;
      }
    }

    const response = await fetch(
      `${this.AZURE_API_URL}/GetParkInfo?parkName=${this.VENUE_NAME}&partyName=${this.PARTY_NAME}`
    );
    const data = await response.json();
    this.PHONE_NUMBER = data.phoneNumber;
    this.PARK_BRAND = data.parkBrand;
    this.PARK_NUMBER = data.parkNumber;
    this.ROLLER_NAME = this.ROLLER_NAME = this.VENUE_NAME.split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    const expiryDate = new Date();
    expiryDate.setHours(expiryDate.getHours() + 23);

    const prevData = localStorage.getItem(`extraParkInfo-${this.PARTY_NAME}`);
    let prevDataParsed = null;
    if (prevData) {
      prevDataParsed = JSON.parse(prevData);
      this.PHONE_NUMBER = prevDataParsed.phoneNumber;
      this.PARK_BRAND = prevDataParsed.parkBrand;
      this.PARK_NUMBER = prevDataParsed.parkNumber;
      this.ROLLER_NAME = this.VENUE_NAME.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    this.DB_INTERNAL_ID = data.dbInternalId;

    localStorage.setItem(
      `extraParkInfo-${this.PARTY_NAME}`,
      JSON.stringify({
        ...prevDataParsed,
        parkBrand: data.parkBrand,
        parkNumber: data.parkNumber,
        phoneNumber: data.phoneNumber,
        partyCapacity: data.partyCapacity,
        rollerName: data.rollerName.toLowerCase().replace(/ /g, '').trim(),
        rollerVenueName: data.rollerVenueName,
        parkNameUrl: data.parkNameUrl,
        id: data.dbInternalId,
        guestServicesTextNumber: data.guestServicesTextNumber,
        expiry: expiryDate.toISOString()
      })
    );

    this.NEEDS_PRODUCT_STORAGE_REFRESH = true;
  }

  async makeSingleRequest(keyword: string, body?: any, API_URL: string = this.AZURE_API_URL) {
    const venueName = this.VENUE_NAME;
    const headers = new Headers();

    headers.append('Accept', 'application/json, text/plain');

    const shouldUseCacheHeaders =
      keyword === 'FETCH_VENUE_INFO' || keyword === 'FETCH_PRODUCT_DETAIL';

    if (shouldUseCacheHeaders) {
      headers.append('Cache-Control', 'public, max-age=604800');
    }

    // check captcha was verified at least a minute ago
    const needsCookieRefresh =
      this.LAST_CAPTCHA_VERIFICATION &&
      new Date().getTime() - this.LAST_CAPTCHA_VERIFICATION.getTime() > 60000;

    //check for captcha cookie
    if (
      (!Cookies.get('skyzonegrcjs') || needsCookieRefresh) &&
      window.grecaptcha &&
      keyword !== 'FETCH_VENUE_INFO'
    ) {
      let retries = 0;
      let captchaVerified = false;

      while (retries < this.MAX_RETRIES && !captchaVerified) {
        const d = await this.verifyCaptcha(keyword, retries);
        if (d !== 'success') {
          retries++;
          await new Promise(resolve => setTimeout(resolve, 5000)); // Add a 5-second delay before retrying
        } else {
          captchaVerified = true;
          break;
        }
      }
      if (!captchaVerified) {
        throw new Error('Captcha failed');
      }
      this.LAST_CAPTCHA_VERIFICATION = new Date();
    }

    const response = await fetch(
      `${API_URL}/Request?parkName=${this.VENUE_NAME}&partyName=${this.PARTY_NAME}`,
      {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify({ keyword, venueName, body })
      }
    );

    const data = await response.json();
    return data;
  }

  async makeRequest(endpoint: string, body?: any, API_URL: string = this.AZURE_API_URL) {
    const venueName = this.VENUE_NAME;

    const response = await fetch(
      `${API_URL}${endpoint}?parkName=${this.VENUE_NAME}&partyName=${this.PARTY_NAME}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json, text/plain'
        },
        body: JSON.stringify({
          ...body,
          venueName
        })
      }
    );

    const data = await response.json();
    return data;
  }

  async fetchVenueInfo() {
    await this.syncExtraInfo();
    return await this.makeSingleRequest('FETCH_VENUE_INFO');
  }

  async fetchProductAvailability(query: string) {
    return await this.makeSingleRequest('FETCH_PRODUCT_AVAILABILITY', { extra: query });
  }

  async fetchProductDetail(productCategory?: string) {
    return await this.makeSingleRequest('FETCH_PRODUCT_DETAIL', { extra: productCategory });
  }

  async getCustomerDetail(customerId: string) {
    return await this.makeSingleRequest('GET_CUSTOMER_DETAIL', { extra: customerId });
  }

  async validateDiscountCode(payload: DiscountCodePayload) {
    return await this.makeSingleRequest('VALIDATE_DISCOUNT_CODE', payload);
  }

  async createDraftBooking(payload: DraftBookingPayload) {
    return await this.makeSingleRequest('CREATE_DRAFT_BOOKING', payload);
  }

  async createBooking(payload: any) {
    return await this.makeSingleRequest('CREATE_BOOKING', payload);
  }

  async publishBooking(bookingId: string) {
    return await this.makeSingleRequest('PUBLISH_BOOKING', { uniqueId: bookingId });
  }

  async fetchBookingCosts(payload: DraftBookingPayload) {
    return await this.makeSingleRequest('FETCH_BOOKING_COSTS', payload);
  }

  async capacityReservation(payload: CapacityReservationPayload) {
    return await this.makeSingleRequest('CAPACITY_RESERVATION', payload);
  }

  async deleteCapacityReservation(capacityReservationId: string) {
    return await this.makeSingleRequest('DELETE_CAPACITY_RESERVATION', {
      uniqueId: capacityReservationId
    });
  }

  async updateBooking(bookingId: string, payload: UpdateBookingPayload) {
    return await this.makeSingleRequest('UPDATE_BOOKING', { ...payload, extra: bookingId });
  }

  async triggerSFMC(payload: any) {
    return await this.makeRequest('/TriggerSFMC', {
      ...payload,
      parkBrand: this.PARK_BRAND,
      parkNumber: this.PARK_NUMBER,
      rollerName: this.ROLLER_NAME,
      parkNameUrl: this.PARK_NAME_URL,
      phoneNumber: this.PHONE_NUMBER
    });
  }

  async getEditBookingInfo() {
    return await this.makeRequest(`/GetBookingInfo`, { urlSafeData: this.BOOKING_REF });
  }

  async getCategorizedProducts() {
    return await this.makeRequest(`/GetProductCategories`);
  }

  async validateCaptcha(token: string, retries: number) {
    return await this.makeRequest(`/VerifyRequest`, {
      token,
      retries,
      isFromReviewPage: this.IS_FROM_REVIEW_PAGE
    });
  }

  async getProductId() {
    const storedExtraInfo = localStorage.getItem(`extraParkInfo-${this.PARTY_NAME}`);
    if (storedExtraInfo && !this.NEEDS_PRODUCT_STORAGE_REFRESH) {
      const extraInfo = JSON.parse(storedExtraInfo);
      const extraInfoExpiryDate = new Date(extraInfo.expiry);

      if (
        extraInfoExpiryDate > new Date() &&
        extraInfo.partyName === this.PARTY_NAME &&
        extraInfo.rollerName === this.VENUE_NAME.replaceAll('-', '')
      ) {
        return extraInfo.productId;
      }
    }
    if (this.DB_INTERNAL_ID !== -1 || this.NEEDS_PRODUCT_STORAGE_REFRESH) {
      const response = await fetch(
        `${this.AZURE_API_URL}/GetProductId?parkName=${this.VENUE_NAME}&partyName=${this.PARTY_NAME}&id=${this.DB_INTERNAL_ID}`
      );
      const data = await response.json();

      const prevData = localStorage.getItem(`extraParkInfo-${this.PARTY_NAME}`);
      let prevDataParsed = null;
      if (prevData) {
        prevDataParsed = JSON.parse(prevData);
      }

      localStorage.setItem(
        `extraParkInfo-${this.PARTY_NAME}`,
        JSON.stringify({
          ...prevDataParsed,
          partyName: this.PARTY_NAME,
          productId: data?.productId?.toString()
        })
      );
      return data.productId.toString();
    }
    return null;
  }
}

export default new API(window.location.pathname === '/review');
