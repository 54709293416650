import React, { useContext } from 'react';
import classnames from 'classnames';

import { StepsContext } from '../contexts/steps';
import type { StepsContextValueType, Step } from '../contexts/steps';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';
import Svg from '../components/Svg';

function ProgressBar() {
  const {
    steps: [stepsData],
    beforeGoToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const {
    isEditMode,
    isDecreasingJumpers: [isDecreasingJumpers],
    paymentReady: [paymentReady]
  } = useContext(DataContext) as DataContextValueType;

  const handleStepClick = (step: Step) => {
    beforeGoToStep(step.value);
  };

  const getStepLabel = (step: Step) => {
    if (isDecreasingJumpers && step.value === 'select-addons') {
      return 'Review Add-ons';
    }
    return step.buttonLabel;
  };

  const getStepIcon = (step: Step, index: number) => {
    if (isDecreasingJumpers && step.value === 'select-addons') {
      return (
        <div className="progress-bar__step__icon">
          <img src="/icons/warning-circle.svg" width={24} height={24} alt="step done" />
        </div>
      );
    } else if (step.value === 'checkout') {
      if (step.done) {
        return (
          <div className="progress-bar__step__number progress-bar__step__done">
            <span className="progress-bar__step__icon">
              <Svg content="tada" />
            </span>
          </div>
        );
      } else if (step.active) {
        return (
          <div className="progress-bar__step__number progress-bar__step__active">
            <span className="progress-bar__step__icon">
              <Svg content="tada" />
            </span>
          </div>
        );
      } else {
        return (
          <div className="progress-bar__step__number">
            <span className="progress-bar__step__icon">
              <Svg content="tada" />
            </span>
          </div>
        );
      }
    } else if (step.done && !step.active) {
      return <div className="progress-bar__step__number progress-bar__step__done">{index + 1}</div>;
    } else {
      return <div className="progress-bar__step__number">{index + 1}</div>;
    }
  };

  const activeStepIndex = stepsData.findIndex(step => step.active);
  const progressWidth = `${((activeStepIndex + 0.5) / (stepsData.length - 1)) * 100}%`;

  return (
    <section className={classnames('progress-bar', { 'progress-bar--disabled': paymentReady })}>
      <div className="container">
        <div className={classnames('progress-bar__steps')}>
          <div
            className="progress-bar__progress"
            style={{ '--progress-width': progressWidth } as React.CSSProperties}
          ></div>
          {stepsData.map((step, index) => (
            <button
              key={step.value}
              disabled={
                (!step.done && !step.active) ||
                (isDecreasingJumpers &&
                  (step.value === 'review-order' ||
                    step.value === 'checkout' ||
                    step.value === 'select-date-time'))
              }
              className={classnames({
                'progress-bar__step': true,
                'progress-bar__step--active': step.active,
                'progress-bar__step--done': step.done,
                'progress-bar__step--disabled':
                  (!step.done && !step.active) ||
                  (isDecreasingJumpers &&
                    (step.value === 'review-order' ||
                      step.value === 'checkout' ||
                      step.value === 'select-date-time')),
                'progress-bar__step--not-clickable':
                  (isEditMode && index === 3) ||
                  (isEditMode && index === 4) ||
                  (isDecreasingJumpers &&
                    (step.value === 'review-order' ||
                      step.value === 'checkout' ||
                      step.value === 'select-date-time'))
              })}
              onClick={() => handleStepClick(step)}
            >
              <>
                {getStepIcon(step, index)}
                <span className="progress-bar__step__heading">{getStepLabel(step)}</span>
              </>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProgressBar;
