import type { Step } from '../contexts/steps';

export const steps: Step[] = [
  {
    buttonLabel: 'Details',
    heading: 'ENTER GUEST DETAILS',
    value: 'enter-guest-details',
    active: true,
    done: false
  },
  {
    buttonLabel: 'Date & Time',
    heading: 'SELECT DATE AND TIME',
    value: 'select-date-time',
    active: false,
    done: false
  },
  {
    buttonLabel: 'Add-ons',
    heading: 'INCLUDED ITEMS & ADD-ONS',
    value: 'select-addons',
    active: false,
    done: false
  },
  {
    buttonLabel: 'Review',
    heading: 'REVIEW YOUR ORDER',
    value: 'review-order',
    active: false,
    done: false
  },
  {
    buttonLabel: 'Complete',
    heading: 'CHECKOUT',
    value: 'checkout',
    active: false,
    done: false
  }
];
